import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import endPointApiUrl from "../api/app_api";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function SectionApps(props) {

    const { t, i18n } = useTranslation()

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const _getData = useCallback(async () => {

        setLoading(!loading);

        axios.get(endPointApiUrl(4, 'DESC', 24), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {

            console.log(response.status)
            console.log(response.data)

            setData(response.data)
            setLoading(false);
        });

        // return null;
    }, []);

    useEffect(() => {
        // const source = axios.CancelToken.source();

        _getData();

        // return () => {
        //     source.cancel();
        // };

    }, []);

    if(loading){
        return <div className={'container vh-100 text-center pt-5'}>
            <h2 className="display-2 lead">
                Loading apps...
            </h2>
        </div>
    }

    return (
        <section id={
            'apps'
        } className={'pt-5'}>
            <Helmet>
                <meta name="author" content='Yaroslav Lukyanchuk'/>
                {
                    data?.data && data.data.length > 0 && data.data.map((app, index) =>
                        <meta key={index} property="article:tag" content={i18n.language === 'en' ? app.name.en : app.name.ua }/>)
                }
            </Helmet>
            <div className="h-100 container p-5">
                <div className="">
                    <h4 className={'display-2'}>{t('my.apps')}</h4>

                    <div className="d-flex flex-wrap">

                        {
                            data?.data == null ? <div className="p-1 col-md-6 col-12 p-2">
                                    <div className="row m-1 border">
                                        <div className="col-md-4 p-3">
                                            <img src="https://www.epifinder.com/wp-content/uploads/2015/12/iphone-blank.png"
                                                 alt="ios" className={'img-fluid'}/>
                                        </div>
                                        <div className="col-md-8">
                                            <a href="/app/ioan-app"
                                               className={'text-uppercase text-secondary lead text-decoration-none'}>
                                                <h2 className={'display-5 mt-2'}>
                                                    Apps Name
                                                </h2>
                                            </a>
                                            <p className={'lead text-secondary'}>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum ex excepturi
                                                reprehenderit? A accusantium blanditiis ea, est, eum eveniet...
                                            </p>
                                            <div className="d-flex">
                                                <img src="/image/ios.jpeg" alt="ios"
                                                     className={'img-fluid col-md-6 col-12 p-1'}/>
                                                <img src="/image/android.jpeg" alt="ios"
                                                     className={'img-fluid col-md-6 col-12 p-1'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : data.data.map((app) =>
                                <div className="col-md-6 p-1" key={app.id}>

                                    <a href={'/app/' + app.slug}
                                       className={'text-secondary lead text-decoration-none'}>
                                        <div className="box border bg-white rounded-5 p-5">

                                            <div className="d-flex row">

                                                <div className="col-md-6 col-12 d-flex flex-column justify-content-between"
                                                     // style={{
                                                     //     backgroundImage: "url(" + "https://cdn-useast1.kapwing.com/video_image-1DN5yOlfH.png?Expires=1693713607&GoogleAccessId=dev-sa-videoprocessing%40kapwing-dev.iam.gserviceaccount.com&Signature=WRcZqe%2BqOW4Yw6UD3sCMFHniJIu0d%2Fn6FXOtZ6kraIMkvpbIAoD%2FPFhz9MuOu0D5TuJmx7Nj2sjU5ETMaJ2oljswMTMkp9GaMFEif5Sn42ZhqZW83MITcIVWsV8535WWYLz0ROPaHwwC0coJ2M7E7WPleBc4c6aIKxlXLg%2BNxh6na%2F7TfkxtIUVjCNFaclq19TR0usrcDYPGDQbU2qTOBNGtakIAdSjDSKvmgneQXBXQ7SBa57Pj6GAwj1p7K%2FGJNLC%2FH2nF3hLFrtZb2b9sMYfvokcGUJphhHqwuk775siRx81iCdM3WUfz0r4MUwccdbWhqCq%2BzFiJ7QRVRS%2FQwg%3D%3D" + ")",
                                                     //     backgroundPosition: 'center',
                                                     //     backgroundSize: 'cover',
                                                     //     backgroundRepeat: 'no-repeat',
                                                     //     height: 520
                                                     // }}
                                                >
                                                    <div className="info pt-4">
                                                        <small className="text-secondary">category</small>
                                                        <h2 className={'text-uppercase'}>

                                                            {
                                                            i18n.language === 'en' ?
                                                                ( app.name.en.length > 15 ? app.name.en.substring(0, 15) + '...' : app.name.en)
                                                                : ( app.name.ua.length > 15 ? app.name.ua.substring(0, 15) + '...' : app.name.ua)
                                                        }</h2>
                                                        <small className="text-secondary">
                                                            {/*{app.service.name.en}*/}

                                                            {
                                                                i18n.language === 'en' ?
                                                                    ( app.description.en.length > 50 ? app.description.en.substring(0, 50) + '...' : app.description.en)
                                                                    : ( app.description.ua.length > 50 ? app.description.ua.substring(0, 50) + '...' : app.description.ua)
                                                            }
                                                        </small>
                                                    </div>

                                                    <div className="d-flex pb-2 row mt-md-0 mt-5 pb-4">
                                                        {/*<img src="/image/ios.jpeg" alt="ios"*/}
                                                        {/*     className={'img-fluid col-md-6 col-12 p-1'}/>*/}
                                                        {/*<img src="/image/android.jpeg" alt="ios"*/}
                                                        {/*     className={'img-fluid col-md-6 col-12 p-1'}/>*/}
                                                        <a href={'/app/' + app.slug} className={'btn btn-dark p-3 col-md-12 col-12'}>
                                                            {t('learn.more')}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-12 p-4 pb-0">
                                                    <div className="d-flex align-items-center justify-content-center p-5 d-none d-md-block"
                                                         style={{
                                                             backgroundImage: "url(" + "/image/iphoneNEW.png" + ")",
                                                             backgroundPosition: 'center',
                                                             backgroundSize: 'cover',
                                                             backgroundRepeat: 'no-repeat',
                                                             height: 430
                                                         }}
                                                    >
                                                        <img src={app.image.url} alt="logo_app" className={'img-fluid mt-5'}/>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </a>
                                </div>
                            )
                        }

                    </div>


                    <div className="text-center pt-5">

                        <button className="btn btn-dark p-3 col-md-4 col-12" onClick={_getData}>
                            {t('load.more')}
                        </button>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionApps;
