import React from "react";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import {useTranslation} from "react-i18next";

function PageLoyauts(props) {
    const { t, i18n } = useTranslation()

    return (
        <div className={'wrapper'}>
            <HeaderComponent />
            <main>
                {
                    props.page
                }
            </main>
            <FooterComponent />
        </div>
    );
}

export default PageLoyauts;
