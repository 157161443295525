import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
// import styles from "../section/styles.module.css";
import '../section/tamplate.css';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import FooterComponent from "../components/footer";
import HeaderComponent from "../components/header";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import axios from "axios";
import QRCode from "react-qr-code";
import {Helmet} from "react-helmet";

import {MotionAnimate} from "react-motion-animate";
import {Parallax, ParallaxLayer} from "@react-spring/parallax";
import styles from "../section/styles.module.css";
import {Gallery, Item} from "react-photoswipe-gallery";

gsap.registerPlugin(ScrollTrigger);

function DetailAppPage(props) {

    const {t, i18n} = useTranslation()

    const {name} = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const _getData = () => {

        setLoading(!loading);

        axios.get('https://office.lukyanchuk.com/api/front-end/project/' + name, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {

            console.log(response.status)
            console.log(response.data)

            setData(response.data.data)
            setLoading(false);
        });

    }

    const component = useRef();
    const slider = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".panel");
            gsap.to(panels, {
                xPercent: -100 * (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: slider.current,
                    pin: true,
                    scrub: 1,
                    snap: 1 / (panels.length - 1),
                    end: () => "+=" + slider.current?.offsetWidth
                }
            });
        }, component);
        return () => ctx.revert();
    });

    useEffect(() => {
        _getData();
    }, []);

    const taskPDF = data?.files && data.files
        .filter(file => (file.name_file.includes('.pdf') || file.name_file.includes('.docx') || file.name_file.includes('.doc')))
        .map((file, i) => <a key={i} href={file.url} target={'_blank'} className={'text-decoration-none'}>
            <small className="text-secondary align-items-center">
                {t('task')}
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" className="bi bi-box-arrow-up-right m-2"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                    <path fill-rule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                </svg>
            </small>
        </a>);

    const downloadAppInstall = data?.files && data.files
        .filter(file => (file.name_file.includes('.apk') || file.name_file.includes('.exe') || file.name_file.includes('.dmg')))
        .map((file, i) => <div className={'text-center align-items-center'}>

            <QRCode
                style={{height: "50%", maxWidth: "100%", width: "100%"}}
                value={file.url}
            />

            <a key={i} href={file.url} download className={'text-decoration-none '}>

                <small className="text-secondary align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                         className="bi bi-download m-2" viewBox="0 0 16 16">
                        <path
                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path
                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>

                    {t('download')}
                </small>
            </a>

        </div>);

    if (loading) {
        return <div className={'container vh-100 text-center pt-5'}>
            <h2 className="display-2 lead">
                Loading {name}...
            </h2>
        </div>
    }

    return (
        <div id={'top'}>

            <Helmet>
                <title>{i18n.language === 'ua' ? data?.name.ua : data?.name.en}</title>
                <link rel="canonical" href="https://apps.lukyanchuk.com"/>
                <meta name="description"
                      content={i18n.language === 'ua' ? data?.description.ua : data?.description.en}/>
                <meta name="keywords"
                      content="Apps, ios app, android app, flutter, flutter app, mobile application. native app"/>
                <meta name="author" content='Yaroslav Lukyanchuk'/>
                {
                    data?.tags && data?.tags.length > 0 && data.tags.map((tag, index) =>
                        <meta key={index} property="article:tag" content={tag.label}/>)
                }
                <meta property="og:title" content={i18n.language === 'ua' ? data?.name.ua : data?.name.en}/>
                <meta name="og:description"
                      content={i18n.language === 'ua' ? data?.description.ua : data?.description.en}/>
                <meta property="og:url"
                      content={"https://apps.lukyanchuk.com/app/" + name}/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content={data?.image.url}/>
                <meta property="og:video" content="Your video URL"/>
                <meta property="og:audio" content="Your audio URL"/>
                <meta property="og:site_name" content="Apps Lukaynchuk"/>
            </Helmet>

            <div className={"App"} ref={component}>

                <HeaderComponent/>

                <div className="firstContainer bgCustom">

                    <Parallax pages={1.01}>
                        <ParallaxLayer offset={0} speed={0.5}
                                       style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div id={'top'} className={'p-5'}>
                                <h1 className={'lead display-1 text-white font-monospace'}>
                                    {i18n.language === 'ua' ? data?.name.ua : data?.name.en}
                                </h1>
                                <small className={styles.scrollText + ' text-white'}>{t('scroll.down')}</small>
                            </div>
                        </ParallaxLayer>

                        <ParallaxLayer offset={0.02} speed={1.5}
                                       style={{
                                           display: 'flex',
                                           position: "absolute",
                                           alignItems: 'center',
                                           justifyContent: 'flex-end'
                                       }}>

                                <div className="col-md-6 d-flex align-items-center justify-content-center p-5"
                                     style={{
                                         backgroundImage: "url(" + "/image/iphoneNEW.png" + ")",
                                         backgroundPosition: 'center',
                                         backgroundSize: 'cover',
                                         backgroundRepeat: 'no-repeat',
                                         backgroundColor: 'transparent',
                                         // borderRadius: 60,
                                         height: 800,
                                         width: 350,
                                         margin: 'auto'
                                     }}
                                >
                                    <img src={data?.image.url} alt="logo_app" className={'img-fluid'}/>
                                </div>
                        </ParallaxLayer>
                    </Parallax>

                </div>
                <div id={'desc'} className="firstContainer bg-dark">
                    <MotionAnimate
                        delay={0.4}
                        speed={2}
                        ease={[0.75, 0.45, 0.53, 0.94]}
                        reset={true}>
                        <div className="container" style={{height: '25vh'}}>
                            <div className={'box border bg-white rounded-5 p-5 w-100 shadow-lg row m-0'}>
                                <div className="text-start p-3 col-md-10 col-12">
                                    <small className="text-secondary">
                                        {t('title.app')}
                                    </small>
                                    <p className={'lead'}> {i18n.language === 'ua' ? data?.description.ua : data?.description.en} </p>

                                    <p className={'lead text-secondary'}>
                                        {
                                            data?.tags && data.tags.map((tag, i) => <span key={i}>#{tag.label} </span>)
                                        }
                                    </p>
                                    {/*{i18n.language === 'ua' ? data?.service.name.ua : data?.service.name.en}*/}
                                </div>
                                <div className={'col-md-2 col-12'}>

                                    {
                                        (data?.links?.repository_url)
                                        && <div>
                                            <small className="text-secondary">
                                                Repository
                                            </small>
                                            <a href={data.links.repository_url}
                                               className={'text-decoration-none text-dark d-flex align-items-center'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-git m-1" viewBox="0 0 16 16">
                                                    <path
                                                        d="M15.698 7.287 8.712.302a1.03 1.03 0 0 0-1.457 0l-1.45 1.45 1.84 1.84a1.223 1.223 0 0 1 1.55 1.56l1.773 1.774a1.224 1.224 0 0 1 1.267 2.025 1.226 1.226 0 0 1-2.002-1.334L8.58 5.963v4.353a1.226 1.226 0 1 1-1.008-.036V5.887a1.226 1.226 0 0 1-.666-1.608L5.093 2.465l-4.79 4.79a1.03 1.03 0 0 0 0 1.457l6.986 6.986a1.03 1.03 0 0 0 1.457 0l6.953-6.953a1.031 1.031 0 0 0 0-1.457"/>
                                                </svg>
                                                <small> github</small>
                                            </a>
                                        </div>
                                    }


                                    <br/>
                                    {
                                        taskPDF?.length > 0 && taskPDF.map((task, i) => <div key={i}>{task}</div>)
                                    }

                                    <a href={"/privacy-policy/" + name} target={'_blank'} className={'text-decoration-none text-dark d-flex align-items-center'}>
                                        {t('privacy.term')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </MotionAnimate>
                </div>
                <div ref={slider} className="container1">
                    <div
                        className="description panel bgCustom d-flex flex-column align-items-center justify-content-center">
                        <h2 className={'display-1 text-white lead'}>{t('screenshots')}</h2>

                        <div className={'text-white'}>
                            {t('scroll.down')}
                            <div className="scroll-down">
                                <div className="arrow"/>
                            </div>
                        </div>
                    </div>
                    {
                        data?.files && data.files
                            .filter(file => (
                                file.name_file.includes('.png') || file.name_file.includes('.jpg') || file.name_file.includes('.jpeg'))
                                || file.name_file.includes('.mp4') || file.name_file.includes('.mov') || file.name_file.includes('.avi')
                            )
                            .map((file, i) => <div key={i}
                                                   className="panel bg-white p-5 d-flex flex-column text-center align-items-center justify-content-center">


                              <div>
                                  {
                                      (file.name_file.includes('.mp4') || file.name_file.includes('.mov') || file.name_file.includes('.avi')) ?
                                          <video src={file.url} className={'img-fluid rounded-5 shadow-lg'} controls/>
                                          : <img src={file.url}
                                                 alt={file.name_file}
                                                 className={'img-fluid rounded-5 shadow-lg-'}/>
                                  }
                                  <br/>
                                  <small className="text-secondary">
                                      #{i + 1}
                                  </small>
                              </div>

                            </div>)
                    }
                    {/*<div*/}
                    {/*    className="panel bg-white p-5 bgCustom d-flex flex-column text-center align-items-center justify-content-center">*/}
                    {/*    <a href={"/app/" + name + "#download"}*/}
                    {/*       className={'btn btn-dark rounded-5 p-4 col-md-12 col-12'}>*/}
                    {/*        <small className="display-1">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"*/}
                    {/*                 className="bi bi-download m-2" viewBox="0 0 16 16">*/}
                    {/*                <path*/}
                    {/*                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>*/}
                    {/*                <path*/}
                    {/*                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>*/}
                    {/*            </svg>*/}
                    {/*            {t('download')}*/}
                    {/*        </small>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                <div
                    className="lastContainer bgCustom d-flex flex-column text-center align-items-center justify-content-center">

                    <div id={'download'} className="container" style={{height: '25vh'}}>
                        <div className={'box border bg-white rounded-5 p-5 w-100 shadow-lg'}>
                            <div className="text-start p-3">
                                <small className="text-secondary">
                                    {t('download.app')}
                                </small>

                                <div className="row align-items-center">
                                    {
                                        (data?.links.ios)
                                            ?
                                            <img src="/image/ios.jpeg" alt="ios"
                                                 className={'img-fluid col-md-3 col-12 p-1 rounded-4'}/>

                                            : <div className="col-md-3"></div>
                                    }{
                                    (data?.links.android)
                                        ? <img src="/image/android.jpeg" alt="ios"
                                               className={'img-fluid col-md-3 col-12 p-1 rounded-4'}/>
                                        : <div className="col-md-3"></div>
                                }
                                    <div className="col-md-3"></div>


                                    <div className="col-md-3 col-12">
                                        {
                                            downloadAppInstall && downloadAppInstall.map((app, i) => <>{app}</>)
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container text-center mt-5 pt-5" style={{height: '15vh'}}>
                        <a href="#top" className={'btn btn-dark rounded-5 p-3 col-md-5 m-auto col-12 mt-5'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                            </svg>
                        </a>
                    </div>


                </div>

                <FooterComponent/>
            </div>
        </div>
    );
}

export default DetailAppPage;
