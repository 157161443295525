import React from "react";
import {useTranslation} from "react-i18next";

function Page404(props) {

    const { t, i18n } = useTranslation()

    return (
        <div id={'page-404'} className="vh-100 d-flex align-items-center justify-content-center">
            <div className="box rounded-5 p-5 w-50 h-50 d-flex flex-column align-items-center justify-content-center">
                <h1 className={'display-1'}>
                    {t('error.oops')}
                </h1>
                <small className={'lead text-secondary'}>
                    {t('error.404')}
                </small>
                <p className={'lead'}>
                    {t('error.text')}
                </p>
                <a href="/" className={'btn btn-dark w-50'}>{t('btn.back')}</a>
            </div>
        </div>
    );
}

export default Page404;
