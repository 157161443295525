import React from "react";
import styles from './styles.module.css'
import {Helmet} from "react-helmet";

function Section01(props) {
    return (
     <section>
         {
             props.page == 'app' ?
                 <div className="h-100">
                     <div className="row">
                         <div
                             className="col-md-8 text-center justify-content-center align-content-center align-items-center d-flex flex-column">
                             <div
                                 className="border p-5 bg-black text-white h-50 w-100 justify-content-center align-content-center align-items-center d-flex flex-column">
                                 <h1 className={'text-uppercase display-1'}>{props.title}</h1>
                             </div>
                             <div className="p-5 col-md-8 m-auto text-justify">
                                 <div className="row">
                                     <img src="/image/ios.jpeg" alt="ios" className={'img-fluid col-md-6 col-12 p-5'}/>
                                     <img src="/image/android.jpeg" alt="android"
                                          className={'img-fluid col-md-6 col-12 p-5'}/>
                                 </div>
                             </div>
                         </div>
                         <div className="col-md-4">
                             <div className="image-app text-center pt-5 pb-5 vh-100">
                                 <img src={props.image} alt="image-app" className={'img-fluid h-100'}/>
                             </div>
                         </div>
                     </div>
                 </div> :
                 <div className="d-flex justify-content-center align-items-center pt-5">
                     <div className="title text-center pt-md-0 pt-5"  id={'m'}>
                         {/*<h2 className={'display-1'}>*/}
                         {/*    Welcome*/}
                         {/*</h2>*/}
                         <a href="#apps">
                             <img src="/image/iphone.png" alt="ios" className={'img-fluid phone'}/>
                         </a>
                         {/*<br/>*/}
                         {/*<small className="text-secondary">*/}
                         {/*    <i>*/}
                         {/*        Scroll down*/}
                         {/*    </i>*/}
                         {/*</small>*/}
                     </div>
                     {/*<div className="row position-relative">*/}
                     {/*    <div className="col text-center">*/}
                     {/*        <div className="row vh-100">*/}
                     {/*            <div className="col-md-6 h-75 bg-black text-white justify-content-center align-content-center align-items-center d-flex flex-column">*/}
                     {/*                <h1 className={'display-1'}>*/}
                     {/*                    APPS*/}
                     {/*                </h1>*/}
                     {/*            </div>*/}
                     {/*            <div className="col-md-6 h-75 bg-white">*/}
                     {/*            </div>*/}
                     {/*            <div className="col-md-12 h-25 bg-bottom-">*/}
                     {/*            </div>*/}
                     {/*        </div>*/}
                     {/*    </div>*/}
                     {/*    <div className="col position-absolute text-center vh-100">*/}
                     {/*        /!*<img src={props.image} alt="image-app" className={'img-fluid p-5'}/>*!/*/}
                     {/*    </div>*/}
                     {/*</div>*/}
                 </div>
         }
     </section>
    );
}

export default Section01;
