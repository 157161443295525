import React from "react";
import Section01 from "../section/section_01";
import SectionApps from "../section/section_apps";
import {Helmet} from "react-helmet";

function MainPage() {
    return (
        <div className="sections">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Apps by Yaroslav Lukyanchuk</title>
                <link rel="canonical" href="https://apps.lukyanchuk.com" />
                <meta name="description" content={'Flutter Apps on iOS and Android by Yaroslav Lukyanchuk'}/>
                <meta name="keywords"
                      content="Apps, ios app, android app, flutter, flutter app, mobile application. native app"/>
                <meta property="og:title" content={'Apps by Yaroslav Lukyanchuk'}/>
                <meta name="og:description" content={'Flutter Apps on iOS and Android by Yaroslav Lukyanchuk'} />
                <meta property="og:url"
                      content={"https://apps.lukyanchuk.com/"}/>
                <meta property="og:image"
                      content={'/image/app.png'}/>
                <meta property="og:type" content="article" />
                <meta property="og:video" content="Your video URL"/>
                <meta property="og:audio" content="Your audio URL"/>
                <meta property="og:site_name" content="Apps Lukaynchuk"/>
            </Helmet>
            <Section01
                title={'apps'}
                description={'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam assumenda beatae consequatur, delectus, error est harum illum incidunt inventore ipsam omnis sequi totam! Doloremque iusto nesciunt perferendis quibusdam repellendus.'}
                image={'/image/app.png'}
            />
            <SectionApps />
        </div>
    );
}

export default MainPage;
