import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import FooterComponent from "../components/footer";
import HeaderComponent from "../components/header";

function PagePrivacyPolicy(props) {

    const { t, i18n } = useTranslation();

    const {name} = useParams();

    const _titleApp = name.split('-')[0].charAt(0).toUpperCase() + name.split('-')[0].slice(1) + ' App';
    return (
        <div className={'col-md-6 m-auto'}>

            <h1 className={'mt-5'}>
                {_titleApp}
            </h1>
            <div id={'page-privacy-policy'} className="d-flex align-items-center justify-content-center pt-2 pb-2 mb-5">

                <div className="box rounded-5 p-5 d-flex flex-column align-items-start justify-content-center">
                    <h1 className={'display-'}>
                        {t('privacy.term')}
                    </h1>
                    <small className={'text-secondary'}>
                        04/04/2024 Updated
                    </small>
                    <p className={'mt-5'}>
                       Yaroslav Lukaynchuk built the {_titleApp} as a Free app. This SERVICE is provided by Yaroslav Lukaynchuk at no cost and is intended for use as is. This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service. If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at {_titleApp} unless otherwise defined in this Privacy Policy.
                    </p>

                    <h5>Information Collection and Use</h5>
                    <p>
                        Information Collection and Use For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information. The information that I request will be retained on your device and is not collected by me in any way. The app does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the app
                        <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">Google Play Services</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noreferrer">Firebase Analytics</a></li></ul>
                    </p>



                        <h5>
                            Types of Data collected
                        </h5>
                        <p>
                            Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies; Usage Data; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example). Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection. Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application. Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service. Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner. Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available. Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.
                        </p>

                        <h5>
                            Cookies
                        </h5>
                        <p>
                            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service. .
                        </p>

                        <h5>
                            Security
                        </h5>
                        <p>
                            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.
                        </p>

                        <h5>
                            Links to Other Sites
                        </h5>
                        <p>
                            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </p>

                        <h5>Children’s Privacy</h5>
                        <p>
                            These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.
                        </p>

                        <h5>
                            Changes to This Privacy Policy
                        </h5>
                        <p>
                            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                        </p>

                        <h5>
                            Contact Us
                        </h5>
                        <p>
                            If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at email : yarik@lukyanchuk.com
                        </p>

                    <a href="/" className={'btn btn-dark col-md-3 col-12 mt-5 m-auto'}>{t('btn.back')}</a>
                </div>
            </div>
        </div>
    );
}

export default PagePrivacyPolicy;
