import React from "react";
import {useTranslation} from "react-i18next";

function HeaderComponent(props) {

    const {t, i18n} = useTranslation()

    const headerTitle = t('app');
    // window.location.pathname === '/' ? 'Apps' : window.location.pathname.split('/')[2].replace('-', ' ');

    return (
        <header className={'bg-white- container-fluid fixed-top'}>

            <div className="container d-flex justify-content-between align-items-center pt-2 pt-md-0">
                <h1 className={'text-dark text-uppercase p-1'}>
                    <a href="/" className={'text-decoration-none text-dark'}>{headerTitle}</a>
                </h1>

                <div className={'d-flex align-items-center'}>


                    {/*<span className={'p-1'}>*/}
                    {/*    EN*/}
                    {/*</span>*/}
                    <span className={'p-1 mt-1'}>
                        <a href="/#apps" className={'text-decoration-none text-dark'}>{t('all.apps')}</a>
                    </span>

                    {
                       ( window.location.pathname !== '/' &&  !window.location.pathname.includes('privacy-policy')) &&
                        <a href="#download" className={'mt-0 mb-0 m-2 btn btn-dark rounded-5 shadow-lg d-none d-sm-block'}>
                            <small className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-download m-2" viewBox="0 0 16 16">
                                    <path
                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                    <path
                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                </svg> {t('download')}
                            </small>
                        </a>
                    }

                    <a href="https://t.me/YaroslavLukyanchuk" target={'_blank'} className="text-decoration-none lead">
                        <small className={'text-secondary m-2'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-telegram text-dark" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                            </svg>
                        </small>

                    </a>

                    <span className={'p-1 mt-1'} onClick={() => i18n.changeLanguage(i18n.language !== 'ua' ? 'ua' : 'en')}>
                           {i18n.language === 'ua' ? 'UA' : 'EN'}
                    </span>

                </div>
            </div>

        </header>
    );
}

export default HeaderComponent;
