
import './App.css';
import PageLoyauts from "./layouts/page";
import {Routes, Route, Link, Navigate} from "react-router-dom";
import SinglePage from "./pages/single";
import Page404 from "./pages/404";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPage from "./pages/main";
import DetailAppPage from "./pages/app";
import PagePrivacyPolicy from "./pages/privacy_policy";

function App() {
  return (
    <>
        {/*routes*/}
        <Routes>
            <Route index exact path={'/'} element={<PageLoyauts page={  <MainPage />} />}/>

            <Route exact path={'/app/:name'} element={<DetailAppPage />}/>

            <Route exact path={'/privacy-policy/:name'} element={<PageLoyauts page={  <PagePrivacyPolicy />} />}/>

            <Route path="*" element={<PageLoyauts page={  <Page404 />} />}/>
        </Routes>
    </>
  );
}

export default App;
